<template>
   
  <div class="container">
    
    <div class="controls">
      <button class='flag-button-small' @click="this.selectLanguage('en-US')" :class="{'selected flag-button': this.language === 'en-US'}">
        🇺🇸
      </button>
      <button class='flag-button-small' @click="this.selectLanguage('pt-BR')" :class="{'selected flag-button': this.language === 'pt-BR'}">
        🇧🇷
      </button>
      <button class='flag-button-small' @click="this.selectLanguage('es-ES')" :class="{'selected flag-button': this.language === 'es-ES'}">
        🇪🇸
      </button>
    </div>
    <br><br>
    <div class="controls">
    <button @click="toggleAction" :class="{
        'control-btn': true,
        'recording': isRecording,
        'stop-recording': !isRecording && !isPlaying,
        'playing': isPlaying,
        'generating': isCallingVoiceGPT
      }">
  
      <i v-if="!isRecording && !isPlaying && !isCallingVoiceGPT" class="fas fa-microphone-alt"></i>
      <i v-else class="fas fa-stop-circle"></i>
    </button>
  </div>

    <textarea readonly v-model="this.transcript" class="transcription" rows="3"></textarea>
    <textarea readonly v-show="false" v-model="this.longTranscript" class="transcription"></textarea>
  </div>
</template>



<script>


export default {
  data() {
    return {
      isRecording: false,
      isPlaying: false,
      recognition: null,
      utterance: null,
      language: 'en-US',
      transcript: '',
      longTranscript: '',
      isCallingVoiceGPT: false,
      audioSource: null,
      mediaRecorder: null,
      audioData: null,
      audioURL: null

    };
  },
  
  mounted() {

     
    // check for SpeechRecognition API support
    const SpeechRecognition =  window.webkitSpeechRecognition;

    console.log(SpeechRecognition)
    if (SpeechRecognition) {
      // initialize speech recognition
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;  // Enable continuous recognition
      this.recognition.interimResults = false;  // Enable interim results
      this.recognition.lang = this.language;
      this.longTranscript = '';
      this.transcript = '';

      this.recognition.onresult = (event) => {
        
        this.transcript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          
          if (event.results[i].isFinal) {
            // Append the transcript to the textarea
            this.transcript = event.results[i][0].transcript;
            
            // Check if the longTranscript is empty, meaning that it is the first sentence
            if(this.longTranscript == ''){
              this.longTranscript = this.transcript
            }else{ // If it is not the first sentence, add a space before appending the new sentence
              this.longTranscript += ' ' + this.transcript.trimStart()
            }
           }else{
            this.transcript += event.results[i][0].transcript;
          }
        }
       

      };

      this.recognition.onend = () => {
        console.log('Speech recognition service has stopped. Check if should restart...');
        if (this.isRecording){
          console.log('Restarting speech recognition service')
          this.recognition.start();
        }
        else{
          console.log('Not restarting service')
        }

      };

      this.recognition.onerror = (event) => {
        console.error(event.error);
        this.isRecording = false
      };

     

      } else {
        console.error('SpeechRecognition is not supported in this browser.');
        this.isRecording = false
      }
  },
 

  methods: {
  
    selectLanguage(myLanguage) {
      console.log('Language Selection')
      console.log(myLanguage)
      this.language = myLanguage;
      this.recognition.lang = this.language
      // Add logic here to change the language of the speech recognition
    },


    toggleAction() {
      
        if (this.isRecording ) {
          this.stopTranscription(); // method to stop recording
          this.isRecording = false     
          this.transcript = 'Calling GPT...'     
          this.callVoiceGPT()

        }else{
          if (this.isPlaying){
            this.isPlaying = false;
            this.stopPlayback()
          }else{
            this.isRecording = true;
            this.transcribeAudio()
          }
        }       
        
    },




     async transcribeAudio() {
      // start speech recognition
      console.log('Start transcription')
     
      if (this.recognition) {
        this.recognition.lang = this.language;
        this.longTranscript = '';
        this.transcript = '';
        
        this.recognition.start();
        
      }

    },

     stopTranscription() {
      // stop speech recognition
      if (this.recognition) {        
        this.recognition.stop();
      }
    },
    
        
    stopPlayback(){
      this.isPlaying = false
      this.audioSource.pause();
      this.audioSource.currentTime = 0;
  },
      
   startPlayback(){
      this.isPlaying = true
      this.audioSource = new Audio(this.audioURL);  // assuming the json response contains a 'link' field with the mp3 link
      this.audioSource.addEventListener('ended', this.stopPlayback);
      this.audioSource.play();
   },

   async callVoiceGPT(){

        
        if (this.longTranscript.length > 0) {

              console.log('Calling Voice GPT')
              console.log('Transcript', this.longTranscript)
              this.isCallingVoiceGPT = true;
             
        
              //const objectivesList = this.formData.objectives.split(";").map((item) => item.trim());
              const queryString = `user_message=${encodeURIComponent(this.longTranscript)}` +'&language=' + this.language;
              const api = "https://ymnlv42fsd4ze7mvvh5w6wjyue0fjluj.lambda-url.us-east-1.on.aws/?"    
              const url = api + queryString;

              try {
                // Replace with your API endpoint
                const response = await fetch(url, {
                  method: "GET"
                });
        
                // Handle the response from the API
                if (response.ok) {
                  
                  //const data = await response.json();
                  const data = await response.json();
                  console.log('Response Received from Backend',  data);
                  
                  this.longTranscript = ''
                  this.transcript =''
                  this.audioURL = data['presigned_url']
                  this.startPlayback()
                  
                  

                  

                } else {
                  
                  //const data = await response.json();
                  
                  console.error("Error:", response.status, response.statusText);
            

                }
              } catch (error) {
                  
                  
                  console.error("Error:", error);
            
                  

              } finally {

                  
                  this.isCallingVoiceGPT = false;
      

              }
            

          }
   }
   
  },
};

</script>




<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
 
}

.controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  
}

.control-btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.control-btn.recording {
  background-color: #f44336;
}
.control-btn.playing {
  background-color: #ff8d29; /* Yellow color */
  /* Add more styles if necessary */
}
.control-btn.generating{
  background-color: #3d6eff; /* Yellow color */
  /* Add more styles if necessary */
}

.selected {
  border: 4px solid #000; /* Or choose any color you prefer */
  box-sizing: border-box; /* To prevent the border from changing the button size */
}

.transcription {
  width: 300px;
  height: 150px;
  margin-top: 20px;
  border: none;
  overflow-y: auto;
  font-size: 24px;
}
 

.flag-button-small {
  font-size: 40px;
}



</style>